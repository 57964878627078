
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper blog-02-12-24">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Our Blogs</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <div class="news-page">
          <div class="news-page-intro-container">
            <div class="news-page-text-container">
              <h2>Check out all of our latest blogs from <br/>Ignite Facilities</h2>
              <h4 class="news-page-subtext"> 
              </h4>      
            </div>      
          </div>

          <div class="">      
            <!-- Project Navigation Tile -->
            <section class="image-text floors news-page-grid">
              <Grid class="pt2" center middle>
                <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                  <div class="tile-container">
                    <!-- Create newer blogs to the top of the list -->

                    

                    <!-- 24-03-25 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2025/march/24-03-25.jpg" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">5 Plumbing Issues That Could Halt Your Operations – And How to Prevent Them</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="5-Plumbing-Issues-That-Could-Halt-Your-Operations–And-How-to-Prevent-Them" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 17-03-25 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2025/march/17-03-25.jpg" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">Future-Proofing Facilities with Renewable Energy</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="Future-Proofing-Facilities-with-Renewable-Energy" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 10-03-25 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2025/march/10-03-25.jpg" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">How HVAC Maintenance Improves Air Quality and Employee Wellbeing</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="How-HVAC-Maintenance-Improves-Air-Quality-and-Employee-Wellbeing" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 24-02-25 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2025/feb/24-02-25.jpg" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">Electrical Safety in the Workplace</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="Electrical-Safety-in-the-Workplace" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 17-02-25 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2025/feb/17-02-25.jpg" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">Exploring Government Grants for Heat Pumps</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="Exploring-Government-Grants-for-Heat-Pumps" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 10-02-25 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2025/feb/10-02-25.jpg" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">How-to-Prepare-Your-Facility-for-Winter</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="How-to-Prepare-Your-Facility-for-Winter" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 23-12-24 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2024/23-12-24-img1.png" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">Energy-Efficient Plumbing Solutions to Cut Costs in Commercial Buildings</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="Energy-Efficient-Plumbing-Solutions-to-Cut-Costs-in-Commercial-Buildings" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 19-12-24 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2024/19-12-24-img1.png" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">Heat Pumps vs. Traditional Boilers</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="Heat-Pumps-vs-Traditional-Boilers" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 16-12-24 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/blog/2024/16-12-24-img1.png" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">The Benefits of a One-Stop Maintenance Partner for Commercial Properties</h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="The-Benefits-of-a-One-Stop-Maintenance-Partner-for-Commercial-Properties" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>

                    <!-- 02-12-24 Blog-->
                    <div class="tile">
                      <div class="tile-inner">                
                        <img src="/img/02-12-24-img1.png" alt="">
                        <div class="tile-text">
                          <h3 class="news-page-headline">The Complete Guide to Facilities Management: </h3>
                          <div class="services-grid-article-button-container">
                            <router-link to="The-Complete-Guide-to-Facilities-Management" class="services-grid-article-button">
                              <!-- <button class="client-button">Read More</button> -->
                              <div class="client-button-container">
                                <button class="client-button">
                                  <svg viewBox="0 0 180 60" class="border">
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                  </svg>
                                  <span>Read More</span>
                                </button>
                              </div>
                            </router-link>
                          </div>
                        </div>                   
                      </div>
                    </div>
                    
                  </div>
                </div>
              </Grid>
            </section>
          </div>
        </div>

        <!-- Benefits Section section -->
        <div class="masonry-wrapper heating-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>             
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Our Blogs",
  metaInfo: {
    title: 'Our Blogs - Ignite Facilities',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Our Blogs - Trusted Heating Engineers in Stoke-on-Trent | Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Ignite Facilities offers trusted heating services in Stoke-on-Trent. Contact us for reliable heating solutions for your property.'
      }
    ]
  },
  methods: {
    track() {
      this.$gtag.pageview("/blogs");
    },
  },
  components: {
    CaseStudies
  }
};
</script>


<style lang="scss">

.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}

.blog-02-12-24 {
  background-image:  url(/img/blog/02-12-24.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
